import React from "react"
import { graphql } from "gatsby"

import ContactForm from "../components/contactForm"
import SEO from "../components/seo"

const ContactPage = ({ data }) => (
  <div className="contactPage pageContent base--shiftContent">
    <SEO
      title="Contact"
      keywords={[`Sioux Lookout`, `hockey`, `Contact`, `info`]}
    />
    <h1 className="base__pageTitle">Contact SLMHA</h1>
    <div className="contactPage__infoContainer">
      <p>
        For general enquiries please contact{" "}
        <span style={{ fontWeight: "bold" }}>Keith Lawrance</span>,
        Communications Convener.
      </p>
      <div>
        <p>
          <b>Email</b>
          <br />
          <a href="mailto:klawrance@windigo.on.ca?subject=Minor%20Hockey">
            klawrance@windigo.on.ca
          </a>
        </p>
        <p>
          <b>Phone</b>
          <br />
          <a href="tel:+1-807-738-0025">(807) 738-0025</a>
        </p>

        <div className="contactPage__mailContainer">
          <div>
            <span style={{ display: "block", textAlign: "center" }}>
              <b>Mail</b>
            </span>
            <span style={{ fontStyle: "italic" }}>
              Sioux Lookout Minor Hockey Association
            </span>
            <br /> PO Box 1085
            <br />
            Sioux Lookout, ON
            <br />
            P8T 1A0
            <br /> Canada
          </div>
        </div>
        {/* <p style={{ textAlign: "center", paddingTop: "25px" }}>
          <i>Or use the contact form below:</i>
        </p> */}
      </div>
    </div>

    {/* <ContactForm /> */}
  </div>
)

export default ContactPage

export const pageQuery = graphql`
  query contactQuery {
    contactImg: file(relativePath: { eq: "sampleImage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
