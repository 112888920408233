import React from "react"

const ContactForm = () => {
  return (
    <form
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      className="contactForm"
      action="/sent"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <div className="formField">
        <label className="contactForm__label" htmlFor="name">
          Name
          <span className="contactForm__required"> *</span>
        </label>
        <input
          type="text"
          name="name"
          id="name"
          placeholder="Your name"
          required
        />
      </div>
      <div className="formField">
        <label htmlFor="email" className="contactForm__label">
          Email<span className="contactForm__required"> *</span>
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="email@address.ca"
          required
        />
      </div>
      {/* Removed validation for phone number b/c faced issues with hyphen on mobile. Needs a more elegant solution for validation here. */}
      <div className="formField">
        <label htmlFor="phone" className="contactForm__label">
          Phone
        </label>
        <input type="tel" name="phone" placeholder="555-555-5555" />
      </div>
      <div className="formField"></div>
      <div className="formField">
        <label htmlFor="message" className="contactForm__label">
          Message<span className="contactForm__required"> *</span>
        </label>
        <textarea
          name="message"
          placeholder="Send us a message"
          cols="25"
          rows="10"
          required
        />
      </div>
      <button
        type="submit"
        id="contactForm__submit"
        className="bg-secondary text-primary hover:bg-primary hover:text-white px-5 py-2 font-bold tracking-wide uppercase"
      >
        Send
      </button>
      {/* <input type="submit" value="submit" /> */}
      <p className="contactForm__requiredFieldsText">
        <span className="contactForm__required">*</span> Required
      </p>
    </form>
  )
}

export default ContactForm
